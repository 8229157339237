<template>
  <div class="p-6">
    <form class="wrap-form">
      <b-card>
        <h5 class="text-success mb-6">Thông tin khóa học</h5>
        <b-col cols="4" style="min-height: 120px">
          <div class="image-upload">
            <v-file-input
              hide-input
              v-model="file"
              accept="image/png, image/jpeg"
              class="icon-edit"
              @change="onUpload"
              prepend-icon="mdi-pencil-outline"
              truncate-length="15"
            ></v-file-input>
            <v-img :src="preview" :aspect-ratio="2 / 1"></v-img>
          </div>
          <div class="error text-error">
            <p v-if="!isImageValid" class="mb-1">
              Kích thước ảnh tối đa nhỏ hơn 512kb !
            </p>
            <p v-if="!isImage" class="mb-1">
              Định dạng ảnh phải là JPG hoặc PNG
            </p>
          </div>
          <label
            class="d-flex flex-column align-items-center justify-content-center"
            >Hình ảnh nhóm, tỷ lệ 2:1
            <span class="d-block">
              Tối đa 512kb, kích thước đề xuất 640:320px</span
            ></label
          >
        </b-col>

        <b-row>
          <b-col>
            <basic-select
              label="Gói dịch vụ"
              placeholder="--- Chọn gói dịch vụ ---"
              track-by="code"
              value-label="name"
              :options="this.packagesName"
              :value.sync="selectedPackage"
              @change="clearDates"
              required
              name="this.data"
              v-validate="'required'"
              changeValueByObject
            />
          </b-col>
          <b-col cols="5">
            <basic-input
              label="Tên khóa"
              required
              placeholder="Nhập Tên khóa"
              name="group_name"
              v-validate="{
                required: true,
                alpha_num_dot: true,
                max: 200,
              }"
              v-model="group_name"
              maxlength="200"
            />
          </b-col>
          <b-col cols="3">
            <basic-select
              label="Nhóm Zalo chung"
              required
              placeholder="Chỉ hiển thị các nhóm zalo chung"
              track-by="group_link"
              value-label="name"
              name="zalo_group_name"
              v-model="zaloOption"
              :options="this.zaloGroup"
              :multiple="false"
            />
          </b-col>

          <b-col cols="3">
            <basic-input
              label="Mã khóa"
              required
              placeholder="Mã khóa"
              name="name"
              v-validate="{
                required: true,
                alpha_num_dot: true,
                max: 30,
              }"
              v-model="defineName"
              disabled="true"
            />
          </b-col>

          <b-col cols="4">
            <basic-input
              label="Link nhóm Zalo chung"
              placeholder="Nhập link nhóm Zalo chung"
              class="required"
              v-model="link_zalo"
              required
              maxlength="200"
              disabled="true"
            >
            </basic-input>
          </b-col>

          <!-- drop down list-->

          <b-col cols="3">
            <basic-select
              label="Health Coach chính"
              required
              placeholder="Chỉ định coach chính cho khóa"
              track-by="id"
              value-label="fullName"
              name="coach"
              v-model="coach_id"
              :options="this.coachesList"
              :multiple="false"
            />
          </b-col>
          <!-- :options="this.filterCoachList" -->

          <b-col cols="3">
            <basic-select
              label="Health Coach phụ"
              required
              placeholder="Chỉ định coach cho khóa"
              track-by="id"
              value-label="fullName"
              name="coach_support"
              v-model="coach_support_id"
              :options="this.coachesList"
              :multiple="true"
            />
          </b-col>
          <!-- drop down list-->

          <b-col cols="1">
            <basic-input
              label="Số tuần"
              :allow-empty="false"
              v-model="selectedPackage.duration"
              required
              disabled="true"
              changeValueByObject
            />
          </b-col>

          <b-col cols="2">
            <p>Ngày bắt đầu khóa *</p>

            <date-picker
              label="Ngày bắt đầu khóa"
              format="DD-MM-YYYY"
              :allow-empty="false"
              placeholder="Chọn ngày bắt đầu"
              required
              name="status"
              changeValueByObject
              v-model="startDate"
              @change="generateDates"
            />
          </b-col>

          <b-col cols="2">
            <!-- <basic-input
              label="Ngày kết thúc khóa"
              :allow-empty="false"
              v-model="formatDateEnd"
              required
              name="status"
              
              changeValueByObject
            /> -->
              <p>Ngày kết thúc khóa *</p>
            <date-picker
              label="Ngày kết thúc khóa"
              format="DD-MM-YYYY"
              :allow-empty="false"
              placeholder="Ngày kết thúc khóa"
              required
              name="status"
              changeValueByObject
              v-model="dateEndObject"
            />
          </b-col>

          <!-- <b-col cols="6">
            <basic-input
              :id="`brithday-group`"
              label="Link Group Coach chính"
              placeholder="Nhập đường dẫn"
              class="required"
              label-for="brithday-ID"
              v-model="link_groupzalo"
            >
            </basic-input>
          </b-col> -->
        </b-row>

        <table class="weeksTable">
          <thead>
            <tr>
              <th>Tuần</th>
              <th>Ngày bắt đầu</th>
              <th>Ngày kết thúc</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, index) in weeksBreakdown" :key="index">
              <td>{{ week.weekNumber }}</td>
              <td>{{ formatDate(week.startDate) }}</td>
              <td>{{ formatDate(week.endDate) }}</td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </form>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'FormCoachProfile',
  components: {
    'date-picker': DatePicker,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    coachesList: {
      type: Object,
      default: null,
    },
    coursesData: {
      type: Object,
      default() {
        return this.$route.params.coursesData || null;
      },
    },
    coursesZaloId: {
      type: Object,
      default() {
        return this.$route.params.coursesZaloId || null;
      },
    },
  },

  data() {
    return {
      group_name: '',
      zaloOption: '',
      zalo_group_name: '',
      zalo_group_id: '',
      isImageValid: true,
      defineName: '',
      fileId: '',
      preview: '',
      file: '',
      weeksBreakdown: [],
      logo: null,
      name: '',
      description: null,
      link_zalo: '',
      link_groupzalo: '',
      coach_id: '',
      coach_support_id: [],
      coach_List: [],
      selectedPackage: '',
      selectedCoach: '',
      startDate: '',
      startDateOutput: '',
      endDateOutput: '',
      formatDateEnd: '',
      numWeeks: '',
      positions: [],
      statuses: [
        {
          name: 'Active',
          value: 1,
        },
        {
          name: 'Inactive',
          value: 0,
        },
      ],
      packagesName: [],
      courseData: [],
      zaloGroup: [],
      member_user_ids: new Set(),
      coachesZaloList: [],
      filterCoachList: [],
      isDuplicateCheck: false,
      number_member_join: 0,
      dateEndObject:'',
    };
  },
  watch: {
    formatDateEnd:{
      handler(newVal)
      {
        console.log("EndDate",newVal)
      }
    },
    // selectedPackage: {
    //   handler: 'generateName',
    //   deep: true
    // },
    // courseData: {
    //   handler: 'generateName',
    //   deep: true
    // }
    selectedPackage: {
      handler(newVal) {
        if (newVal.id) {
          this.generateName();
        }
      },
      deep: true,
    },
    zaloOption: {
      handler(link) {
        if (link) {
          this.link_zalo = link;
          const result = this.zaloGroup.filter(
            (group) => group.group_link === link,
          );
          this.group_id = result[0].group_id;
          this.zalo_group_name = result[0].name;
          // Convert the string of IDs into an array
          this.coachesZaloList = this.parseGroupDescription(
            result[0].group_description,
          );

          const idsArray = this.coachesZaloList.ids
            .split(',')
            .map((id) => id.trim());

          if (result.length > 0) {
            this.filterCoachList = this.coachesList.filter((coach) =>
              idsArray.includes(coach.zaloUserId),
            );
          } else {
            this.filterCoachList = this.coachesList;
          }
        }
      },
      deep: true,
    },

    coach_id: {
      handler() {
        if (this.coach_id != '') {
          const duplicateCoach = this.coursesData.filter((x) =>
            x.coachId.includes(this.coach_id),
          );
          if (duplicateCoach.length > 0) {
            this.$toastr.e({
              title: 'Lỗi!',
              msg: 'Health Coach chính đang vận hành một khóa khác. Vui lòng thay đổi lựa chọn',
            });
            this.isDuplicateCheck = true;
          } else {
            this.isDuplicateCheck = false;
          }
        }

        this.updateMemberUserIds();
      },
      immediate: true,
      deep: true,
    },

    coach_support_id: {
      handler() {
        this.updateMemberUserIds();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {},
  async created() {
    try {
      await this.GetPackage();
      await this.getGroupOA();
    } catch (error) {
      console.error('Error during component creation:', error);
    }
  },
  methods: {
    parseGroupDescription(groupDescription) {
      // Check if groupDescription is valid
      if (!groupDescription) {
        console.error('Invalid groupDescription:', groupDescription);
        return null;
      }

      const parsedData = {};
      const lines = groupDescription.split('\n');

      lines.forEach((line) => {
        const [key, value] = line.split(':');
        if (key && value) {
          // Normalize the key to avoid spaces and case issues
          const normalizedKey = key.trim().toLowerCase().replace(/ /g, '_');
          parsedData[normalizedKey] = value.trim();
        }
      });

      return parsedData;
    },
    updateMemberUserIds() {
      // Clear previous IDs from the Set
      this.member_user_ids.clear();

      // Add coach_id if available
      if (this.coach_id) {
        const result = this.coachesList.find(
          (filter) => filter.id === this.coach_id,
        );
        if (result && result.zaloUserId) {
          this.member_user_ids.add(result.zaloUserId);
        }
      }

      // Add coach_support_id values if available
      if (this.coach_support_id && Array.isArray(this.coach_support_id)) {
        this.coach_support_id.forEach((coachId) => {
          const supportResult = this.coachesList.find(
            (filter) => filter.id === coachId,
          );
          if (supportResult && supportResult.zaloUserId) {
            this.member_user_ids.add(supportResult.zaloUserId);
          }
        });
      }
    },
    formatDateMinusOneDay(date) {
      // Convert the input date to a Date object
      var dateObj = new Date(date);
      // Subtract one day
      dateObj.setDate(dateObj.getDate() + 1);

      // Extract day, month, and year
      var day = ('0' + dateObj.getDate()).slice(-2);
      var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are 0-based, so add 1
      var year = dateObj.getFullYear();

      return `${day}-${month}-${year}`;
    },
    // validateLink() {
    //   if (!this.link_zalo.startsWith('https://zalo.me')) {
    //     this.error =
    //       'Kiểm tra lại đường link nhóm zalo! Vd: https://zalo.me/...';
    //   } else {
    //     this.error = '';
    //   }
    // },

    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    },
    async generateName() {
      try {
        const response = await this.$api.get(
          `/Courses/GenerateName?packageId=${this.selectedPackage.id}`,
        );
        this.courseData = response.data;

        const i = this.courseData + 1;
        this.defineName = this.selectedPackage.code + (i < 10 ? '0' + i : i);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    async getGroupOA() {
      this.$store.commit('context/setLoading', true);
      try {
        const response = await this.$api.get(`/ZaloZnsLog/GetGroupOA`);
        this.zaloGroup = response.data.groups
          .filter((group) => group.group_description.includes('Nhóm Chung'))
          .filter((item) => !this.coursesZaloId.includes(item.group_id));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },

    dropDownWeeks() {
      let startDate = new Date(this.startDate);
      let endDate = new Date(this.endDateOutput);
      this.weeksBreakdown = this.getWeeksBreakdown(
        startDate.setDate(startDate.getDate() + 1),
        endDate,
      );
    },

    getWeeksBreakdown(startDate, endDate) {
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      const totalDays = Math.round((endDate - startDate) / oneDay);
      const numWeeks = Math.ceil(totalDays / 7); // Round up to ensure complete weeks

      const weeks = [];
      let currentWeekStart = new Date(startDate);

      for (let week = 1; week <= numWeeks; week++) {
        const currentWeekEnd = new Date(currentWeekStart);
        currentWeekEnd.setDate(currentWeekEnd.getDate() + 6); // Set to Saturday

        weeks.push({
          weekNumber: week,
          startDate: currentWeekStart.toISOString().slice(0, 10),
          endDate: currentWeekEnd.toISOString().slice(0, 10),
        });

        currentWeekStart.setDate(currentWeekStart.getDate() + 7); // Move to next week
      }

      return weeks;
    },

    returnPage() {
      this.$router.go(-1);
    },

    // Example usage:

    clearDates() {
      this.startDate = '';
      this.generateDates();
      this.endDateOutput = '';
    },
    // generateDates() {
    //   var numWeeks = this.selectedPackage.duration; // Or any number of weeks you want for the trial
    //   var startDate = new Date(this.startDate);
    //   var endDate = new Date(startDate);
    //   endDate.setDate(endDate.getDate() + numWeeks * 7);
    //   this.startDateOutput = startDate.toISOString().slice(0, 10);
    //   this.endDateOutput = endDate.toISOString().slice(0, 10);
    //   //  this.endDateOutput = this.formatDate(endDate);
    //   this.formatDateEnd = this.formatDate(endDate);
    //   console.log("Formatted End Date:", this.formatDateEnd);

    //   this.dateEndObject = new Date(this.formatDateEnd);
    //   console.log("Date End Object:", this.dateEndObject);

    //   this.dropDownWeeks();

    // },
    generateDates() {
  var numWeeks = this.selectedPackage.duration;
  var startDate = new Date(this.startDate);
  var endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + numWeeks * 7);

  this.startDateOutput = startDate.toISOString().slice(0, 10);
  this.endDateOutput = endDate.toISOString().slice(0, 10);

  this.formatDateEnd = this.formatDate(endDate); // Assume this gives DD-MM-YYYY

  // Convert formatDateEnd to YYYY-MM-DD for parsing
  const [day, month, year] = this.formatDateEnd.split('-');
  const formattedForParsing = `${year}-${month}-${day}`;

  this.dateEndObject = new Date(formattedForParsing);

  this.dropDownWeeks();
},


    convertTimestampToDatetime(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      const formattedDatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDatetime;
    },

    // GetData() {
    //   this.createCourses();
    // },
    onUpload() {
      if (!this.file) return;
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        return (this.preview = URL.createObjectURL(this.file));
      } else {
        delete this.file;
        return (this.preview = null);
      }
    },

    resetDropdown(field) {
      this.$validator.reset({ name: field });
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }

      return data.id;
    },

    async createCourses() {
      this.$store.commit('context/setLoading', true);

      if (this.isDuplicateCheck === true) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Vui lòng thay đổi chỉ định Health Coach chính cho khóa!',
        });
        return;
      }
      if (
        this.selectedPackage.id &&
        this.coach_id &&
        this.startDate &&
        this.link_zalo &&
        this.group_name
      ) {
        try {
          // Upload image in parallel to other async tasks
          const imageUploadPromise = this.file
            ? this.uploadImage(this.file).then((fileId) => {
                if (!fileId) throw new Error('Image upload failed');
                return fileId;
              })
            : Promise.resolve(null);
          // if (this.coursesData.coachId.includes(this.coach_id).length > 0) {
          //   this.$toastr.e({
          //     title: 'Lỗi !',
          //     msg: 'Health Coach chính đang vận hành một khóa khác. Vui lòng thay đổi lựa chọn',
          //   });
          // }
          const courseId = this.$uuid.v1();
          const formDataForCourse = new FormData();
          formDataForCourse.append('id', courseId);
          formDataForCourse.append('logo', await imageUploadPromise);
          formDataForCourse.append('name', this.defineName);
          formDataForCourse.append('ZaloGroupId', this.group_id);
          formDataForCourse.append('LinkZaloGroupCommon', this.link_zalo);
          formDataForCourse.append('NumberMemberJoin', this.number_member_join);
          formDataForCourse.append('ZaloGroupName', this.zalo_group_name);
          formDataForCourse.append('GroupName', this.group_name);
          formDataForCourse.append('CoachId', this.coach_id);
          formDataForCourse.append(
            'StartDatetime',
            this.convertTimestampToDatetime(this.startDate),
          );
          formDataForCourse.append(
            'EndDatetime',
            this.convertTimestampToDatetime(this.dateEndObject),
          );
          formDataForCourse.append('Duration', this.selectedPackage.duration);
          formDataForCourse.append('PackageId', this.selectedPackage.id);

          const [res_course] = await Promise.all([
            this.$api.post('/Courses', formDataForCourse, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }),
            // this.assginZaloAdmin(this.member_user_ids), // Moved to its own function for clarity
          ]);

          if (res_course.data.error) {
            throw new Error(res_course.data.error.message);
          }

          await this.addCoachesToCourse(courseId);

          this.$toastr.s({
            title: 'Thành công!',
            msg: 'Khóa học đã được thêm thành công.',
          });
          this.$router.go(-1);
        } catch (error) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message || 'Có lỗi xảy ra khi tạo khóa học',
          });
        } finally {
          this.$store.commit('context/setLoading', false);
        }
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Vui lòng kiểm tra lại các trường bắt buộc',
        });
        this.$store.commit('context/setLoading', false);
      }
    },
    async addCoachesToCourse(courseId) {
      const promises = this.coach_support_id.map((item) => {
        const coursesCoachId = this.$uuid.v1();
        const formDataForCoachSupport = new FormData();
        formDataForCoachSupport.append('id', coursesCoachId);
        formDataForCoachSupport.append('CoursesId', courseId);
        formDataForCoachSupport.append('CoachSupportInchargeId', item);

        return this.$api
          .post('/CoursesCoach', formDataForCoachSupport, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (res.data.error) {
              throw new Error(res.data.error.message);
            }
          });
      });

      return Promise.all(promises);
    },

    async GetPackage() {
      await this.$api
        .get('/Package')
        .then((response) => {
          this.packagesName = response.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },
  },
};
</script>
<style lang="scss">
.image-upload {
  width: 240px;
  height: 120px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}
.text-error {
  color: #ff5756;
  text-align: center;
  margin-top: 20px;
}
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 18px);
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}

.wrap-form {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
}

.left-site {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 16px;
}

.blank {
  height: 16px;
  background: #f2f3f7;
  margin: 0 -30px 30px;
}
.mx-datepicker {
  margin-top: -3%;
}

.weeksTable {
  width: 100%;
  border-collapse: collapse;
}

.weeksTable th,
.weeksTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.weeksTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.weeksTable th {
  background-color: #4caf50;
  color: white;
}
</style>
